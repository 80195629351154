//
//
//
//
//

export default {
  name: 'CollapsiblePanel',
  props: {
    cid: {
      type: String,
      default: '',
    },
    state: Boolean,
  },
  watch: {
    state() {
      this.update();
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    update() {
      const panel = this.$refs[this.cid];

      if (!panel) {
        return;
      }
      if (!this.state) {
        panel.style.maxHeight = null;
        panel.style.opacity = 0;
      } else {
        panel.style.maxHeight = '2000px';
        panel.style.opacity = 1;
      }
    },
  },
};
