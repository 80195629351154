//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Collapsible from '@/shared/components/CollapsiblePanel.vue';

export default {
  name: 'PaymentMethod',
  components: {
    Collapsible,
  },
  data() {
    return {
      cardInfoError: '',
    };
  },
  props: {
    active: Boolean,
    cid: {
      default: '',
      type: String,
    },
  },
};
